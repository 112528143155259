import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

const Layout = ({ isHomePage, children }) => {
  const {
    wp: {
      generalSettings: { title },
    },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
          description
        }
      }
    }
  `)

  return (
    <div className="global-wrapper" data-is-root-path={isHomePage}>
      <header className="global-header">
        <h1 className="main-heading">
            <Link to="/"><span className="bold-heading">Maruthi</span>Sharma</Link>
        </h1>
      </header>
      <main>{children}</main>
      <footer>
        © {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.com">Gatsby</a>
        {` `}
        &amp; Powered by <a href="https://wordpress.org/">WordPress</a> <span role="img" aria-label="calendar">⚡</span>
      </footer>
    </div>
  )
}

export default Layout
